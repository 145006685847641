<template>
    <ul class="navbar-nav">
        <navbar-item :level="0" v-for="(item, index) in items" :item="item" :key="index"></navbar-item>
    </ul>
</template>

<script>
    import NavbarItem from "@/components/NavbarItem.vue";

    export default {
        props: ['items'],
        components: {
            NavbarItem,
        },
        methods: {}
    };
</script>


<style scoped lang="scss">
</style>
