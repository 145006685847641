<template>
    <b-navbar toggleable="lg" class="navbar-vertical navbar-vertical-sm fixed-left navbar-expand-md navbar-light">
        <div class="container-fluid">
            <b-navbar-brand class="p-0 col col-md-auto">
                <router-link class="nav-link pointer" :to="{name:'links'}">
                    <img class="w-50 d-md-none" src="../assets/source/logo.svg" alt="..." />
                    <img class="d-none d-md-block" src="../assets/source/fav.svg" alt="..." />
                </router-link>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <hr class="dropdown-divider d-none d-sm-block" />

            <b-collapse id="nav-collapse" is-nav>
                <navbar :items="menuItems"></navbar>
            </b-collapse>

            <div class="mt-auto"></div>


            <div class="d-md-block d-none text-center position-relative" style="bottom: 15px; left: -3px;">
              <a href="#" @click.prevent="logoutSystem()">
                  <img :src="getIconUrl('quit')" alt="">
              </a>
            </div>
<!--            <div class="dropdown text-center mb-3 dropup">-->

<!--              &lt;!&ndash; Toggle &ndash;&gt;-->
<!--              <a href="#" class="avatar avatar-sm dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--                <div class="avatar avatar-sm">-->
<!--                    <img src="@/assets/img/avatars/profile.png" class="avatar-img rounded-circle">-->
<!--                </div>-->
<!--              </a>-->

<!--              &lt;!&ndash; Menu &ndash;&gt;-->
<!--              <div class="dropdown-menu dropdown-menu-left">-->
<!--                &lt;!&ndash; <hr class="dropdown-divider"> &ndash;&gt;-->
<!--                <a href="#" class="dropdown-item" @click="logoutSystem()">Logout</a>-->
<!--              </div>-->

<!--            </div>-->

        </div>
    </b-navbar>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Navbar from "@/components/Navbar.vue";

export default {
    components: {
        Navbar,
    },
    data() {
        return {
          icons: [
            'appearance',
            'links',
            'quit',
            'settings',
            'social-links',
          ],
        };
    },
    created() {
    },
    computed: {
        
        menuItems() {
            return [
                {
                    label: 'Links',
                    icon: 'fe-link',
                    route: 'links',
                    icon_img: this.getIconUrl('links')
                },
                {
                    label: 'Social Lnk.',
                    icon: 'fe-instagram',
                    route: 'social-links',
                    icon_img: this.getIconUrl('social-links')
                },
                {
                    label: 'Appereance',
                    icon: 'fe-layout',
                    route: 'appearance',
                    icon_img: this.getIconUrl('appearance')
                },
                {
                    label: 'Builder',
                    icon: 'fe-package',
                    route: 'builder',
                    role: 'admin',
                    icon_img: this.getIconUrl('appearance')
                },
                // {
                //     label: 'Media',
                //     icon: 'fe-image',
                //     route: 'media',
                // },
                {
                    label: 'Settings',
                    icon: 'fe-settings',
                    route: 'settings',
                    icon_img: this.getIconUrl('settings')
                },
                {
                    // label: 'Store',
                    // icon: 'fe-package',
                    // route: 'materials',
                    // resource: 'products',
                    // actions: ['create']
                    // children: [
                    //     { label: 'Products', route: 'inventory' },
                    //     { label: 'Categories', route: 'inventory.categories' },
                    //     { label: 'Brands', route: 'inventory.brands' },
                    //     { label: 'Suppliers', route: 'inventory.suppliers' },
                    // ]
                },
            ]
        }
    },
    methods: {
        ...mapActions('auth', ['logout']),

        logoutSystem() {
            this.logout().then(data => {
              this.$router.push({name: 'login'});
            }).catch(error => {
                this.errors = error.message;
            });
        },
        getIconUrl(icon) {
            if ( this.icons.includes(icon) )
                return require('../assets/icons/'+ icon +'.svg');
        }
    },

};
</script>


<style lang="scss" scoped>

</style>
