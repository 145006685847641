<template>
    <li class="nav-item" :class="expandMenu ? 'dropdown show' : 'dropright'">
        <div :class="screenWitdh <= 576 ? 'd-flex justify-content-between align items' : ''">
            <router-link
                class="nav-link pointer"
                :class="{'dropdown-toggle':hasChildren}"
                :exact-active-class="'active'"
                :active-class="''"
                :data-toggle="dataToggle"
                :aria-expanded="expandMenu"
                :aria-controls="'sidebar'"
                :to="{name:item.route ? item.route : '', params:item.params}"
                v-if="item.role === role || item.role !== 'admin'"
            >
                <span
                    v-if="item.count"
                    class="badge badge-pill badge-soft-primary"
                    style="font-size: 10px; position: absolute; right:25px; top:0;"
                >{{ item.count }}</span>
<!--                <i class="fe" v-if="item.icon && !hasChildren" :title="$t(item.label)" :class="item.icon"></i>-->
<!--                <i class="fe" v-if="hasChildren" :class="item.icon"></i>-->
                <img v-if="item.icon && !hasChildren" :src="item.icon_img" :title="$t(item.label)" class="mb-md-2 mr-3 mr-md-0" />
                <span :class="{'d-md-none':level ==0}">{{ $t(item.label) }}</span>
            </router-link>

            <span
                v-if="hasChildren && screenWitdh <= 576"
                class="mr-3"
                variant="white"
                @click="expandMenu = !expandMenu"
            >
                <i class="fe fe-chevron-up" v-if="expandMenu"></i>
                <i class="fe fe-chevron-down" v-else></i>
            </span>
        </div>

        <ul class="dropdown-menu" v-if="hasChildren" :class="{'show ml-4' :expandMenu}">
            <li class="dropdown-header d-none d-md-block">
                <h6 class="text-uppercase mb-0">{{ $t(item.label) }}</h6>
            </li>
            <navbar-item v-for="(child, index) in item.children" :level="level+1" :item="child" :key="index"></navbar-item>
        </ul>
    </li>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: 'navbar-item',
    props: { item: Object, level: Number },
    data() {
        return {
            screenWitdh: null,
            expandMenu: false,
            role: '',
        }
    },
    computed: {
        ...mapState('auth', {
            user: 'payload',
        }),
        ...mapGetters("users", {
            getUserById: "get",
        }),
        hasChildren() {
            return this.item.children ? true : false;
        },
        dataToggle() {
            return this.hasChildren ? `collapse` : ``;
        }
    },
    created() {
        window.addEventListener('resize', this.getScreenWitdh);
        this.getScreenWitdh();
        this.getUser(this.user.userId).then((res)=>{
            this.role = res.role;
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.getScreenWitdh);
    },
    methods: {
        ...mapActions("users", {
            getUser: "get",
        }),
        getScreenWitdh() {
            this.screenWitdh = window.innerWidth;
        },
    }
}
</script>

<style scoped lang="scss">
.nav-link[data-toggle="collapse"]:after {
    display: none !important;
}

.dropdown-menu {
    z-index: 25000 !important;
}
.nav-link.pointer.active {
    img {
        filter: invert(41%) sepia(11%) saturate(7484%) hue-rotate(220deg) brightness(110%) contrast(102%);
    }
}
</style>
