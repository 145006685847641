<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar"
            id="topbar-content"
        >
            <div class="container-fluid" v-if="user">
                <span>
                    My Boolink:
                    <a
                        :href="appUrl + user.username"
                        style="color: #12263f; font-weight: 300"
                        target="_blank"
                    >
                      {{ appUrl + user.username }}
                    </a>
                </span>
            </div>
        </nav>

        <div class="d-flex align-items-center justify-content-center" v-if="theme">
            <div class="smartphone" :style="convertObjectToStyle(theme.style.bg, 'bg')">
                <!-- <div class="bg-image" :style="{ backgroundImage: 'url('+ theme.bgMobileImage.url +')' }"></div> -->
                <div class="container-fluid text-center mt-5">
                    <div class="row mb-4">
                        <div class="col-12 d-flex flex-column align-items-center">
                            <div class="avatar avatar-xl" v-if="Object.keys(user.avatar).length !== 0">
                                <img :src="user.avatar.preview" class="avatar-img rounded-circle">
                            </div>
                            <div class="avatar avatar-xl text-center" v-else>
                                <img src="@/assets/source/Profile.svg" class="w-75">
                            </div>
                            <span class="mt-2">{{'@' + user.username }}</span>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <ul class="list-group">
                                <li
                                    style="list-style:none;"
                                    v-for="(item, index) in links"
                                    :key="index"
                                    v-show="item.active"
                                >
                                    <a
                                        v-if="item.type == 'link'"
                                        class="link"
                                        :style="convertObjectToStyle(theme.style.link)"
                                        target="_blank"
                                        :href="item.link"
                                    >{{ item.title }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="socialLink-wrapper">
                                <li
                                    style="list-style:none;"
                                    v-for="(item, index) in links"
                                    :key="index"
                                    v-show="item.active"
                                >
                                    <a
                                        v-if="item.type == 'socialLink'"
                                        :style="convertObjectToStyle(theme.style.socialLink)"
                                        :href="item.link"
                                        target="_blank"
                                        class="socialLink"
                                    >
                                      <inline-svg
                                          :src="getIconUrl(item.icon)"
                                          width="30"
                                          height="30"
                                          :transformSource="transformSVG"
                                      ></inline-svg>
                                    </a>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        <pre>
            {{links}}
        </pre>-->
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import debounce from 'lodash/debounce';
import InlineSvg from 'vue-inline-svg';

export default {
    components: {
      InlineSvg
    },

    data() {
        return {
            model: {},
            themeId: this.$route.params.themeId,
            appUrl: process.env.VUE_APP_URL + '/',
            brands: [
              'Group 2',
              'Group 3',
              'Group 4',
              'Group 5',
              'Group 6',
              'Group 7',
              'Group 8',
              'Group 9',
              'Group 10',
              'Group 11',
              'Group 12',
              'Group 13',
              'Group 14',
              'Group 15',
              'Group 16',
              'Group 17',
              'Group 18',
              'Group 19',
              'Group 20',
              'Group 21',
              'Group 22',
              'Group 23',
              'Group 24',
              'Group 25',
              'Group 26',
              'Group 27',
              'Group 28',
            ]
        };
    },
    computed: {
        ...mapState("auth", {
            userAuth: "payload",
        }),
        ...mapGetters('users', {
            getUserInStore: 'find',
        }),
        ...mapGetters('themes', {
            getThemeInStore: 'get',
        }),
        ...mapGetters('links', {
            getLinksInStore: 'find',
        }),
        user() {
            return this.getUserInStore().data[0];
        },
        theme() {
            if (!this.user.themeId) {
              return {
                "style": {
                  "bg": {
                    "color": {
                      "type": "text",
                      "value": "#22222"
                    },
                    "background": {
                      "type": "text",
                      "value": "#fff"
                    },
                    "background-color": {
                      "type": "text",
                      "value": ""
                    },
                    "background-repeat": {
                      "type": "select",
                      "value": "no-repeat"
                    },
                    "background-position": {
                      "type": "select",
                      "value": "right center"
                    },
                    "background-size": {
                      "type": "select",
                      "value": "cover"
                    }
                  },
                  "link": {
                    "color": {
                      "type": "text",
                      "value": "black"
                    },
                    "font-weight": {
                      "type": "text",
                      "value": ""
                    },
                    "border": {
                      "type": "text",
                      "value": "1px solid black"
                    },
                    "border-radius": {
                      "type": "text",
                      "value": ""
                    },
                    "background": {
                      "type": "text",
                      "value": ""
                    },
                    "background-color": {
                      "type": "text",
                      "value": ""
                    },
                    "box-shadow": {
                      "type": "text",
                      "value": ""
                    }
                  },
                  "socialLink": {
                    "color": {
                      "type": "text",
                      "value": "black"
                    },
                    "font-weight": {
                      "type": "text",
                      "value": ""
                    },
                    "border": {
                      "type": "text",
                      "value": ""
                    },
                    "border-radius": {
                      "type": "text",
                      "value": ""
                    },
                    "background": {
                      "type": "text",
                      "value": ""
                    },
                    "background-color": {
                      "type": "text",
                      "value": ""
                    },
                    "box-shadow": {
                      "type": "text",
                      "value": ""
                    }
                  }
                },
              }
            }
            return this.getThemeInStore(this.user.themeId);
        },
        links() {
            return this.getLinksInStore({ query: { $sort: { priority: 1 } } }).data;
        }
    },
    created() {
        this.getUser([this.userAuth.userId, { query: { byusername: false } }])
        .then(res => {
            let themeId = ( this.themeId ) ? this.themeId : res.themeId;
            if ( typeof themeId === 'undefined' ) return;
            this.getTheme(themeId).catch( err => {
                console.log(err)
            })
            this.fetchLinks({
                query: {
                    userId: this.userAuth.userId
                }
            }).catch( err => {
                console.log(err)
            })
        })
        .catch( err => {
            console.log(err)
        })
    },
    methods: {
        ...mapActions("users", {
            'getUser': 'get'
        }),
        ...mapActions("themes", {
            'getTheme': 'get'
        }),
        ...mapActions("links", {
            'fetchLinks': 'find'
        }),
        convertObjectToStyle(styles, type) {
            let style = Object.entries(styles).map( ([key, val]) => {
                if (val.value)
                    return `${key}:${val.value}`;
            }).filter(Boolean).join(';');
            if ( type === 'bg' && this.theme.bgMobileImage)
                return "background-image: url("+ this.theme.bgMobileImage.url +");" + style;
            return style;
        },
        getIconUrl(icon) {
          if ( this.brands.includes(icon) )
            return require('../assets/brands/'+ icon +'.svg');
        },
        transformSVG(el) {
          for (let node of el.children) {
            node.setAttribute('fill', this.theme.style.socialLink.color.value ? this.theme.style.socialLink.color.value : 'black')
          }
          return el;
        },
    },
};
</script>

<style lang="scss" scoped>
.socialLink-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
}
.smartphone {
    position: relative;
    width: 310px;
    height: 640px;
    margin: auto;
    border: 10px solid #fff;
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-radius: 40px;
    box-shadow: 0 25px 130px 0 #00000026;
    overflow-y: auto;
}
.link {
    display: block;
    padding: 10px;
    margin: 10px 0;
    transition: .4s linear;
    &:hover {
        background: rgba(173, 173, 173, 0.4);
    }
}
.socialLink {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #222;
    transition: transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}
</style>